import { useState } from "react";
import CirclePack from "./CirclePack";
import "./App.css";

function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedRole, setSelectedRole] = useState();

  const onInitialized = () => {
    setIsInitialized(true);
  };

  const onRoleChange = (role) => {
    setSelectedRole(role);
  };

  return (
    <div className="circle-area">
      <div className="circle-pack-area">
        <CirclePack onInitialized={onInitialized} onRoleChange={onRoleChange} />
      </div>
      <div className="circle-content-area">
        {isInitialized && (
          <>
            {selectedRole && (
              <>
                <h3>{selectedRole.role_name}</h3>
                <pre>{selectedRole.text}</pre>
              </>
            )}
            {!selectedRole && (
              <>
                <h3>
                  This is the official record of the ManKind Project USA
                  Organizational Structure.
                </h3>
                <p>
                  Click on any Circle or Role to learn more about it, including
                  its purpose and accountabilities.
                </p>
                <p>
                  The full list of Members for any Circles is in the “Circle
                  Members” Role within the circle.
                </p>
                <p>
                  Roles listed as “Rep Links” serve to bring concerns of the
                  circle to the larger circle, usually the Lead Organizational
                  Circle.
                </p>
                <p>
                  Circles are similar to business groups, teams, or committees.
                  Each Circle has a Facilitator, a Secretary, and a Lead. Roles
                  are roughly equivalent to job descriptions. Individuals within
                  the MKP USA structure often hold multiple Roles. Roles can be
                  held by volunteers, contractors, or employees. To learn more
                  about the responsibilities of Role Holders, see the
                  constitution.
                </p>
                <p>
                  To learn more about the governance structure for the Board of
                  MKP USA,{" "}
                  <a href="https://mkpusa.org/org-charts/">click here</a>.
                </p>
                <p>
                  For questions about our organizational system, email{" "}
                  <a href="mailto:holacracy@mkpusa.org">holacracy@mkpusa.org</a>
                </p>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default App;
